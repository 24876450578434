body{
  position: static !important;
  overflow: visible !important;
}

.ion-page{
  display: block !important;
  overflow: visible !important;
  position: relative !important;
  height: auto !important;
  contain: none;
}

ion-content{
  position: static !important;
  --overflow: visible !important;
}

* {
  overflow: visible !important;
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /*Firefox*/
  page-break-inside:auto;
}



ion-fab-button{
  display: none;
}

app-main{
  padding: 0 !important;
}

/*
------------------------------
 SMARTLABEL 
------------------------------
*/

.smartlabel-page {
  padding-bottom: 0 !important;
}
.smartlabel-tabs{
  position: relative !important;
}
.smartlabel-footer-sticky{
  position: static !important;
}

